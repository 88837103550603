import style from './index.module.scss';

import {  useNavigate } from 'react-router-dom';
import classNames from 'classnames';
const img1 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/hxgn-1.png';
const img2 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/hxgn-2.png';
export default function Home() {
  const navigate = useNavigate();
  return (
    <div className={style.content}>
      <div className={style.area}>
        <img src={img1} className={style.bgImg} alt="" />
        <div className={classNames(style.desc)}  >
          <div className={style.center}>
            <div className={style.title}>
              基因智链创客空间
            </div>
            <div>
            基于区块链技术的基因编辑行业产融价值互联网平台，集项目团队成员组建、项目团队激励机制设计、产业链资源整合、<br/>
            基因编辑投融资为一体的新一代WEB3.0系统。促进基因编辑行业与区块链技术AI技术的融合，打造新的生态模式。
            </div>
            <div className={style.btn} onClick={()=>{navigate('/hxgn-sqsy?type=1')}}>
              申请使用
            </div>
          </div>
        </div>
      </div>
      <div className={style.area}>
        <img src={img2} className={style.bgImg} alt="" />
        <div className={classNames(style.desc)}  >
          <div className={classNames(style.center,style.color)}>
            <div className={style.title}>
            AI基因编辑模拟实验室
            </div>
            <div>
            为基因编辑工作的前沿研究提供数据分析、机器学习、人工智能工作方面的支持，进一步拓展精准医疗的应用层面。能够解决大量
            <br/>依靠人工、复杂低效的痛点，将实验人员最大限度从重复繁琐的劳动中解放出来，提高效率，将更多时间和精力投入到更有价值的工作中。

            </div>
            <div className={style.btn} onClick={()=>{navigate('/hxgn-sqsy?type=2')}}>
              申请使用
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}
