import Header from './components/header';
import Footer from './components/footer';
import { Outlet, useMatches } from 'react-router-dom';
import './App.css';
export default function Home() {
  let matches = useMatches();
  let customStyle = matches
    .filter(match => Boolean(match.handle?.style))
    .map(i => i.handle.style);
  return (
    <div className='container'>
      <Header />
      <div
        className='main'
        style={{
          paddingBottom: customStyle.length ? customStyle[0].paddingBottom : 0,
        }}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}
