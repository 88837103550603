import style from './index.module.scss';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
const img2 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/sqsy-1.png';

export default function Home() {
  const [name,setName] =  useState('');
  const [mobile,setMobile] =  useState('');
  const [email,setEmail] =  useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  console.log('query',location,queryParams.get('type'))
  const submit = ()=>{
    console.log(name,mobile,email)
  } 
  return (
    <div className={style.content}>
      <div className={style.area1}>
        <div className={style.cycleImg}>
          <img src={img2} className={style.bgImg} alt="" />
        </div>
        <div className={style.text}>
          <div className={style.box}>
            <div className={style.title}>申请使用</div>
            <div>
              <input className={style.input}   onChange={(e)=>setName(e.target.value)}  placeholder="姓名"/>
            </div>
            <div>
              <input className={style.input}   onChange={(e)=>setMobile(e.target.value)}    placeholder="电话"/>
            </div>
            <div>
              <input className={style.input}   onChange={(e)=>setEmail(e.target.value)}   placeholder="电子邮箱"/>
            </div>
            <div className={style.btn} onClick={submit}>
              提交
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
