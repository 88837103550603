import style from './index.module.scss';

import classNames from 'classnames';
const img1 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/zzzjz-1@2x.png';
const img2 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/zzzjz-2@2x.png';
const img3 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/zzzjz-3@2x.png';
const img4 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/zzzjz-4@2x.png';
const img5 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/zzzjz-5@2x.png';
const img6 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/zzzjz-6@2x.png';
export default function Home() {
  return (
    <div className={style.content}>
      <div className={style.area}>
        <img src={img1} className={style.bgImg} alt="" />
        <div className={classNames(style.desc)}  >
          <div className={style.title}>自组织机制。</div>
        </div>
      </div>
      <div className={style.area1}>
        <div className={style.cycleImg}>
          <img src={img2} className={style.bgImg} alt="" />
        </div>
        <div className={style.text}>
          <div>
            <div className={style.title}>开放包容</div>
            <div>
              鼓励各研发团队之间的合作与交流，<br />
              共同参与实验室的各项活动。
            </div>
          </div>
        </div>
      </div>
      <div className={style.area1}>
        <div className={style.text}>
          <div>
            <div className={style.title}>公平公正</div>
            <div>
              遵循公平公正的原则,<br />
              为各研发团队提供平等的机会和资源支持。

            </div>
          </div>
        </div>
        <div className={style.cycleImg}>
          <img src={img3} className={style.bgImg} alt="" />
        </div>
      </div>
      <div className={style.area1}>
        <div className={style.cycleImg}>
          <img src={img4} className={style.bgImg} alt="" />
        </div>
        <div className={style.text}>
          <div>
            <div className={style.title}>激励创新</div>
            <div>
              通过激励机制、共享机制等手段,<br />
              激发研究者的创新热情和积极性。
            </div>
          </div>
        </div>
      </div>
      <div className={style.area1}>




        <div className={style.text}>
          <div>
            <div className={style.title}>协同发展</div>
            <div>
              加强各模块之间的协同合作,<br />
              实现实验室整体运行的高效性和可持续性。

            </div>
          </div>
        </div>
        <div className={style.cycleImg}>
          <img src={img5} className={style.bgImg} alt="" />
        </div>
      </div>
      <div className={style.area1}>
        <div className={style.cycleImg}>
          <img src={img6} className={style.bgImg} alt="" />
        </div>
        <div className={style.text}>



          <div>
            <div className={style.title}>动态调整</div>
            <div>
              根据实验室发展需求和市场变化,<br />
              及时调整各模块的职能和运作方式。
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
