import style from './index.module.scss';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const img2 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/shouye-平台积分@2x.png';

const img1 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/shouye-行动者@2x.png';
const img3 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/shouye-自组织机制@2x.png';
export default function Home() {
  return (
    <div className={style.content}>
      <div className={style.area}>
        <img src={img1} className={style.bgImg} alt="" />
        <div className={classNames(style.desc, style.desc1)}  >
          <div>
          <Link className={style.item} to='/xdz'>
            <div className={style.title}>行动者</div>
            <div className={style.more}>
           
              进一步了解 >
         
            </div>
            </Link>
          </div>

        </div>

      </div>

      <div className={style.area} >
        <img src={img2} className={style.bgImg} alt="" />
        <div className={classNames(style.desc, style.desc2)}  >
          <div>
          <Link className={style.item} to='/ptjf'>
            <div className={style.title}>平台积分</div>
            <div className={style.more}>
      
            进一步了解 >
          
            </div>
            </Link>
          </div>

        </div>
      </div>
      <div className={style.area} >
        <img src={img3} className={style.bgImg} alt="" />
        <div className={classNames(style.desc, style.desc1)}>
          <div>
          <Link className={style.item} to='/zzz'>
            <div className={style.title}>自组织机制</div>
            <div className={style.more}>
       
            进一步了解 >
         
            </div>
            </Link>
          </div>

        </div>
      </div>
    </div>
  );
}
