import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ErrorPage from './error-page';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';




import Home from './pages/home';
import Xdz from './pages/xdz';
import Gnfw from './pages/gnfw';
import Hxgn from './pages/hxgn';
import HxgnSqsy from './pages/hxgn-sqsy';
import Ptjf from './pages/ptjf';
import Zzz from './pages/zzz';
import Yyzz from './pages/yyzz';
import Lxwm from './pages/lxwm';
import Jrwm from './pages/jrwm';
import Gywm from './pages/gywm';
import Jsbj from './pages/jsbj';

// import MedicalAndFoodDetail from './pages/MedicalAndFood/detail';
// //基因分型详情
// import GenotypingDetail from './pages/genotyping/detail';
// //基因分型2轮播图
// import GenotypingSwiper from './pages/genotyping/list';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,

    children: [
      {
        index: true,
        element: <Home />,
      },



      {
        path: 'xdz',
        element: <Xdz />,
        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            paddingBottom: 0,
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'hxgn',
        element: <Hxgn />,
        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            paddingBottom: 0,
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'Gnfw',
        element: <Gnfw />,
        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            paddingBottom: 0,
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'hxgn-sqsy',
        element: <HxgnSqsy />,

        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            paddingBottom: 0,
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'zzz',
        element: <Zzz />,
        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            paddingBottom: 0,
            // footerColor: '#000',
          },
        },
      },

      {
        path: 'ptjf',
        element: <Ptjf />,
        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            paddingBottom: 0,
            // footerColor: '#000',
          },
        },
      },

      {
        path: 'yyzz',
        element: <Yyzz />,
        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            paddingBottom: 0,
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'jsbj',
        element: <Jsbj />,
        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            paddingBottom: 0,
            // footerColor: '#000',
          },
        },
      },
      
      {
        path: 'lxwm',
        element: <Lxwm />,
        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            paddingBottom: 0,
            // footerColor: '#000',
          },
        },
      },

      {
        path: 'jrwm',
        element: <Jrwm />,
        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            paddingBottom: 0,
            // footerColor: '#000',
          },
        },
      },
      {
        path: 'gywm',
        element: <Gywm />,
        handle: {
          style: {
            headerBg: '',
            headerColor: '#000',
            paddingBottom: 0,
            // footerColor: '#000',
          },
        },
      },

  
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
