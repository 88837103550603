import style from './index.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import { useState } from 'react';
import 'swiper/css/pagination';
import classNames from 'classnames';
const video1 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/video/jsbj.mp4';
const img2 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/jsbj-banner1.png';
const img3 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/jsbj-banner2.png';
const img4 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/jsbj-banner3.png';
const img5 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/jsbj-1.png';
const img6 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/jsbj-2.png';
const img7 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/jsbj-3.png';
const img8 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/jsbj-4.png';
const img9 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/jsbj-5.png';
const img10 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/jsbj-6.png';
const img11 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/jsbj-7.png';
const img12 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/jsbj-8.png';
const img13 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/jsbj-9.png';
const img14 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/jsbj-10.png';
const img15 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/jsbj-11.png';
const icon = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/jsbj-icon.png';
const more = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/jsbj-more.png';
const close = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/jsbj-close.png';
export default function Home() {
  const [showModel, setShowModel] = useState(false)

  return (
    <div className={style.content}>
      <div className={style.area}>
        <video src={video1} muted className={style.video} autoPlay playsInline loop></video>
        <div className={classNames(style.desc)}  >
          <div className={style.label}>
            <div className={style.title}>技术介绍</div>

          </div>

        </div>
      </div>
      <div className={style.swWrap}>
        <div className={style.container}>
          <Swiper
            modules={[Pagination, Autoplay]}
            slidesPerView={2}
            spaceBetween={30}
            centeredSlides={true}
            autoplay
            loop
            pagination={{
              clickable: true,

            }}
          >
            <SwiperSlide className={style.swiperSlide}>
              <div className={style.item}>
                <img
                  src={img2}
                  alt=''
                />
                <div className={style.label}>

                  <span className={style.blue}>基因编辑</span>
                  是一种对基因组序列进行精确修饰，以诱导基因组中的插入、缺失或碱基替换的技术。许多疾病都伴随着体内基因表达的改变，特别是一些由单个基因突变引起的遗传性疾病，基因编辑技术有望在基因水平上

                  <span className={style.blue}>控制疾病的发生。</span>

                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <div className={style.item}>
                <img
                  src={img3}
                  alt=''
                />
                <div className={style.label}> 传统的基因编辑工具主要包括一代ZFN、二代TALEN和三代CRISPR技术，ZFN与TALEN技术都是使用包含DNA识别结合域和DNA切割域的核酸酶，但存在靶标识别率低、成本高、脱靶概率高和结构复杂等问题，因此，推动了三代CRISPR技术的发展。</div>
              </div>
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <div className={style.item}>
                <img
                  src={img4}
                  alt=''
                />

                <div className={style.label}>
                  <span className={style.blue}>CRISPR-Cas9系统的工作原理</span>
                  是通过引入特定的RNA序列来引导Cas9蛋白精准剪切目标DNA，从而实现基因的添加、删除或替换。这种方法的简洁性和高效率为基因编辑带来了前所未有的精准性和便捷性，同时也大大推动了合成生物学的发展。合成生物学，作为一个致力于重新设计和构建生物系统以实现特定功能的跨学科领域，因CRISPR-Cas9技术的引入而迎来了新的发展阶段。

                </div>


              </div>
            </SwiperSlide>
          </Swiper>

        </div>
      </div>
      <div className={style.area}>
        <img src={img5} className={style.bgImg} alt="" />
        <div className={classNames(style.desc, style.flex)}  >
          <div className={style.label}>
            <div className={style.title1}>CRISPR系统介绍</div>
            <div>

              CRISPR是“成簇规律间隔短回文重复序列”(clustered regularly interspaced short palindromic repeats)的缩写，原核生物（细菌和古生菌）利用它来防止噬菌体病毒的感染。其原理核心是使原核生物能够识别与噬菌体或其他入侵者相匹配的基因序列，并利用专门的酶将这些序列作为破坏目标，这些专门的酶称为CRISPR相关蛋白Cas（CRISPR associated proteins）。

            </div>
          </div>

        </div>
      </div>
      <div className={style.boxTitle}>
        目前最高效的基因组编辑工具
      </div>

      <div className={classNames(style.area, style.v2)}>
        <img src={img6} className={style.bgImg} alt="" />
        <div className={classNames(style.desc)}  >
          <div className={style.bottomLabel}>
            目前，来自Streptococcus pyogenes（酿脓链球菌）的CRISPR/Cas9系统应用最为广泛。天然的CRISPR-Cas9系统由三部分组成：SpCas9 (以下简称Cas9)、crRNA、tracrRNA。其中，crRNA和tracrRNA通过局部碱基配对组成gRNA(guide RNA)，gRNA与Cas9蛋白结合后引导Cas9蛋白识别和切割目标DNA序列。

          </div>

        </div>
      </div>
      <div className={style.boxTitle}>
        sgRNA (single-guide RNA)
      </div>

      <div className={classNames(style.area, style.v2)}>
        <img src={img7} className={style.bgImg} alt="" />
        <div className={classNames(style.desc)}  >
          <div className={style.bottomLabel}>
            为了方便实验设计以及提高gRNA的稳定性，Jennifer Doudna和Emmanuelle Charpentier（2020年她们共同获得了诺贝尔化学奖）将crRNA和tracrRNA融合成一条RNA，并把其称为sgRNA (single-guide RNA)。改造后的CRISPR/Cas9系统成为研究者做基因编辑的首选工具。

          </div>

        </div>
      </div>

      <div className={style.boxTitleCenter}>
        <div>
          <img src={icon} alt="" />
        </div>
        <div>
          CRISPR/Cas9技术原理
        </div>
      </div>
      <div className={classNames(style.area, style.v2)}>
        <img src={img8} className={style.bgImg} alt="" />
        <div className={classNames(style.desc1)}  >
          <div className={style.bottomLabel2}>
            CRISPR-Cas9技术包含两种重要的组分，一种是行使DNA双链切割功能的Cas9蛋白，而另一种则是具有导向功能的gRNA。CRISPR-Cas9的技术原理包括gRNA引导的Cas9靶向DNA切割和DNA修复两个基本过程。


          </div>
          <div className={style.btn} onClick={() => setShowModel(!showModel)}>
            点击了解更多    <img src={more} className={style.more} alt="" />
          </div>

        </div>
      </div>

      <div className={style.boxTitleCenter}>

        <div>
          基因编辑技术的应用
        </div>
      </div>
      <div className={classNames(style.area, style.v2)}>
        <img src={img9} className={style.bgImg} alt="" />
        <div  >
          <div className={style.bottomLabel3}>
            基因编辑育种主要是通过可再生生物燃料的生产来在<span className={style.blue}>
              能源创新
            </span>方面“发光发热”。生物燃料是化石燃料的替代品，是未来减少温室气体排放和遏制气候变化影响的关键。基因编辑技术能够使植物生产的脂肪、酒精和糖等可再生生物燃料原料打造更充分更可持续的供给源。

          </div>


        </div>
      </div>
      <div className={classNames(style.area, style.v2, style.flex)}>
        <div className={style.item}>
          <img src={img10} className={style.bgImg} alt="" />
          <div className={style.label1}>

            在<span className={style.blue}>园艺应用</span>上，园艺作物被认为包括种植用于审美享受的花卉和观赏植物，而不断开发具有多样性与可取性性状的遗传资源则被视为基因编辑育种用于改良园艺产品的重要价值导向。
          </div>
        </div>
        <div className={style.item}>
          <img src={img11} className={style.bgImg} alt="" />
          <div className={style.label1}>
            在<span className={style.blue}>新材料应用</span>上，环境的可持续性更直接地促使基因编辑技术对塑料、纺织以及纤维等传统材料产业进行改造升级。尽管大多材料生产离不开石化原料，但高污染、高能耗、高排放等问题的解决却也势在必行。而基因编辑育种则为植物生产材料逐步替代传统化工材料提供了更绿色更可持续的解决方案。
          </div>
        </div>
      </div>
      <div className={classNames(style.area, style.v2)}>
        <img src={img12} className={style.bgImg} alt="" />
        <div  >
          <div className={style.bottomLabel3}>
            由于缺乏全基因组以及合适的转化和再生策略，很少有药用植物使用 CRISPR/Cas 基因组工具进行编辑。但近年来可以看到一些植物次生代谢途径（如生物碱、萜类化合物、黄酮类化合物、酚类、皂苷等）已采用基因编辑技术通过敲除、敲入、点突变、基因表达微调和靶向诱变进行工程改造，促进药品和保健品等的原料生产，进一步扩展了
            <span className={style.blue}>植物基因编辑育种</span>的应用场景。

          </div>


        </div>
      </div>

      <div className={classNames({ [style.model]: true, [style.show]: showModel })}>
      <img src={close} className={style.close} onClick={()=>setShowModel(false)} alt="" />
        <div className={style.title}>

          CRISPR/Cas9技术原理
        </div>
        <div className={classNames(style.area, style.v2)}>
          <img src={img8} className={style.bgImg} alt="" />
          <div  >
            <div className={style.bottomLabel3}>
              首先,Cas9蛋白特异性切割目标DNA序列,产生DNA双链断裂Cas9蛋白含有两个核酸酶结构域,可以分别切割DNA两条单链。Cas9蛋白要成功识别目标序列必须满足两个条
              件:<span className={style.blue}>(1)gRNA的5'端20nt和靶DNA之间碱基配对;(2)靶DNA的3'端有合适的PAM序列(PAM是cas9的特异性识别位点,cas9会在PAM上游的第三个碱基处进行切割)。     </span> 
              CRISPR/Cas9切割目标DNA后,产生双链断裂(DSB,double strand break),DSB是一切基于核酸内切酶的基因编辑的基础。然后,细胞内的DNA修复系统修复双链断裂,在
              修复的过程中实现DNA序列的改变。

            </div>


          </div>
        </div>
        <div className={style.area1}>
          <div className={style.cycleImg}>
            <img src={img13} className={style.bgImg} alt="" />
          </div>
          <div className={style.text}>
            <div>
            DNA修复机制分为两类：非同源性末端接合（Non-homologous end joining, NHEJ）和同源介导的修复（Homology-directed repair, HDR）。非同源性末端接合就是通过DNA连接酶将双链断裂末端直接连接的一种修复过程，不依赖于同源DNA序列。这种连接过程简单粗暴，虽然迅速高效，但是会随机造成一些序列的缺失或插入，导致无法精准编辑。
            
            </div>
          </div>
        </div>
        <div className={style.area1}>
          <div className={style.text}>
            <div>
            非同源性末端接合是细胞内主要的DNA断裂损伤修复机制。同源介导的修复则是以未受伤的姐妹染色单体的同源序列作为其修复的模板。虽然同源介导的修复速度较慢，效率较低，但是非常精准，可以使基因组修复到完美如初。如：条件性基因敲除、基因敲进、基因替换、点突变等等。
            </div>
          </div>
          <div className={style.cycleImg}>
            <img src={img14} className={style.bgImg} alt="" />
          </div>
        </div>
    
        <div className={classNames(style.area, style.v2, style.v3)}>
          <img src={img15} className={style.bgImg} alt="" />
          <div  >
            <div className={style.bottomLabel3}>
            综上，CRISPR技术主要是利用位点特异Cas核酸酶在基因组靶位点处引入DNA双链断裂，再经细胞自身的非同源末端连接 (NHEJ) 或同源重组修复(HDR)对双链断裂进行修复，最终实现目标基因敲除和碱基编辑等基因组遗传修饰。可以看出gRNA 和 Cas9是基因组编辑成功的两个关键因素。CRISPR/Cas9之所以颠覆了以往的基因编辑技术，就是因为只需要改变单链向导RNA（sgRNA）的5'端序列即可重编程Cas9的序列特异性，再加上，CRISPR/Cas9合成简单、周期短、操作简单、效率高，因此也就成为了目前研究最多、应用最广泛的基因编辑工具。
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
