import style from './index.module.scss';
const img1 = '//homepage-life-decode.oss-cn-hangzhou.aliyuncs.com/c_connect/img/yingyezhizhao.png';

export default function Home() {
  return (
    <div className={style.content}>
      <div className={style.area}>
        <img src={img1} className={style.bgImg} alt="" />
 

      </div>



    </div>
  );
}
