import style from './index.module.scss';
import {  Link,} from 'react-router-dom';
export default function Footer(props) {
  return (
    <div>
         <div className={style.footer}>
      {/* <div className={style.items} style={{ color: props.color || '#000' }}> */}
        <Link className={style.item} to='/yyzz'>
          营业执照
        </Link>
        <Link className={style.item} to='/jrwm'>
          加入我们
        </Link>
        <Link className={style.item} to='/lxwm'>
          联系我们
        </Link>
        {/* <div className={style.item}>帮助</div>
        <Link
          className={style.item}
          to='https://beian.miit.gov.cn/'
          target='_blank'>
          浙ICP备2023048689号
        </Link> */}
      {/* </div> */}
     
    </div>
       <div className={style.copyright}>
      ICP主体备案号 沪ICP备2024043853号
      </div>
    </div>
 
    
  );
}
